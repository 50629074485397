<template>
  <standard-layout>
    <template v-slot:heading>
      <h1 v-if="reservation && reservation.customer">{{reservation.customer.firstName}}, Deine SPABoot-Reservierung</h1>
    </template>
    <template v-slot:main>
      <div v-if="reservation">
        <div class="card">
          <div class="card-header">Reservierungsnummer: {{ reservation.number }}</div>
          <div class="card-body">
            <h5>{{ reservation.product.name }}</h5>
            <div>{{ reservation.startTime | dateTime }}-{{ reservation.startTime.hour + reservation.duration }}:00
              ({{ reservation.duration }}h)
            </div>
            <div>{{ reservation.product.name }}</div>
            <div>{{ reservation.attendees }} Personen</div>
            <div v-if="reservation.state === 'requested'">aktueller Preis* {{ reservation.price | price }}</div>
            <div v-if="requested" class="text-danger">vorläufig, buchbar bis {{
                reservation.expirationDate | date
              }}
            </div>
            <div v-if="confirmed">Reservierung ist bestätigt und bezahlt.</div>
            <div v-if="canceled" class="text-danger">Diese Reservierung wurde storniert.</div>
            <div v-if="expired" class="text-danger">Diese Reservierung wurde storniert, da sie nicht rechtzeitig bezahlt wurde.</div>
          </div>
        </div>
        <div v-if="requested" class="row mt-3">
          <div class="col-6">
            <button class="btn btn-outline-danger btn-sm"
                    @click="_cancelReservation">
              Reservierung stornieren
            </button>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-primary ml-2"
                    @click="book">
              Zur Buchung
            </button>
          </div>
          <div class="col-12 mt-4 text-right">
            <h5>
              Noch unsicher?
            </h5>
            <div>Ruf uns an, wir helfen gerne weiter:</div>
            <h2>
              <a :href="'tel:' + configuration.phone">
                <font-awesome-icon icon="mobile-alt"/>&nbsp;{{ configuration.phone }}</a>
            </h2>
            <div>Oder nehme schriftlich Kontakt mit uns auf:</div>
            <h2>
              <router-link :to="{name: 'contact'}">
                <font-awesome-icon icon="user-edit"/>&nbsp;Zum Kontaktformular
              </router-link>
            </h2>
          </div>
        </div>
      </div>
      <div v-else
           class="row">
        <h5 class="col-12 mt-3">
          Es konnte leider keine Reservierung mit der Nummer <span
            class="font-weight-bolder">{{ number || 'unbekannt' }}</span> gefunden werden.
        </h5>
        <div class="col-12 form-group text-right mt-4">
          <router-link :to="{name: 'editReservation'}">
            <button class="btn btn-primary ml-2">Jetzt Reservieren</button>
          </router-link>
        </div>
      </div>
      <div class="small text-muted mt-3">
        * Preis, der aktuell bei der festen Buchung anfallen würde.
        Es kann aber bis zum Zeitpunkt der Buchung noch
        Preisanpassungen geben. Insofern ist dieser Preis kein festes Angebot.
      </div>
    </template>
  </standard-layout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    number: String
  },
  computed: {
    ...mapGetters(['reservation', 'configuration']),
    requested() {
      return this.reservation?.state === 'requested'
    },
    confirmed() {
      return this.reservation?.state === 'confirmed'
    },
    canceled() {
      return this.reservation?.state === 'canceled'
    },
    expired(){
      return this.reservation?.state === 'expired'
    }
  },
  methods: {
    ...mapActions(['loadReservation', 'cancelReservation', 'initCartWithReservation', 'startLoading', 'stopLoading']),
    _cancelReservation() {
      this.cancelReservation()
          .then(() => {
            this.showInfo("Du hast Deine Reservierung storniert")
            this.$router.push("/")
          }).catch(error => this.handleError(error))
    },
    book() {
      this.startLoading('Einen Moment bitte ...')
      this.$router.push({name: 'editReservation', params: {number: this.reservation.number}})
          .finally(() => this.stopLoading())
    }
  },
  watch: {
    number: {
      handler(newVal) {
        if (newVal) {
          this.startLoading('Lade Reservierung ...')
          this.loadReservation(newVal)
              .catch(error => this.handleError(error))
              .finally(() => this.stopLoading())
        }
      },
      immediate: true
    }
  },
  activated() {
    if (this.$route.query?.justConfirmed)
      this.$gtag.event('reservation_convert', {})
  }
}
</script>
